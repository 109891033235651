<template>
  <div>
    <TitleWithBackButton title="" back-route="Settings" icon="mdi-graphql"></TitleWithBackButton>
    <CompanyHoursData class="pa-5"></CompanyHoursData>
  </div>
</template>

<script>
import CompanyHoursData from "./CompanyHoursData.vue";
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "StoreHours",
  components: {
    CompanyHoursData,
    TitleWithBackButton
  },
  data: function () {
    return {};
  },
};
</script>

<style></style>