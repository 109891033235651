<template>
  <div class="mt-2">
    <h2>
      {{ translator("companyTurns") }}
    </h2>
    <div class="pb-10">
      <v-row v-for="(day, key) of Object.entries(companyTurns)" :key="key">
        <v-col cols="2">
          <p></p>
          {{ day[1].name }}
        </v-col>
        <v-col cols="2">
          <v-checkbox
            @click="clearSelect(key)"
            v-model="day[1].closed"
            label="Chiuso"
          ></v-checkbox>
        </v-col>
        <v-col cols="4">
          <h4>Mattina</h4>
          <v-select
            :disabled="day[1].closed"
            filled
            v-model="day[1].hours.morning.open"
            :items="morningHours"
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            label="Orario di inizio"
            rounded
          ></v-select>
          <v-select
            class="mt-1"
            filled
            :disabled="day[1].closed"
            :value="day[1].closed ? '' : day[1].hours.morning.close"
            v-model="day[1].hours.morning.close"
            :items="morningHours"
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            label="Orario di fine"
            rounded
          ></v-select>
        </v-col>
        <v-col cols="4">
          <h4>Pomeriggio</h4>
          <v-select
            :disabled="day[1].closed"
            filled
            v-model="day[1].hours.afternoon.open"
            :items="afternoonHours"
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            label="Orario di inizio"
            rounded
          ></v-select>
          <v-select
            class="mt-1"
            filled
            :disabled="day[1].closed"
            v-model="day[1].hours.afternoon.close"
            :items="afternoonHours"
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            label="Orario di fine"
            rounded
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center align-center pa-2">
      <v-btn
        rounded
        class="text-center"
        color="primary"
        @click="setSetting('companyTurns', companyTurns, 'Orari salvati con successo.')"
      >Salva orari</v-btn>
    </div>
    <div class="mt-2">
      <v-row>
        <h2>
          Giorni di chiusura straordinaria
        </h2>
      </v-row>
      <v-row class="mt-5 pt-1 px-5 d-flex justify-center">
        <div style="max-width: 1000px;">
          <v-date-picker
            locale="it"
            v-model="extraordinaryClosure"
            :allowed-dates="allowedDates"
            :min="min"
            first-day-of-week="1"
            :multiple="true"
            show-adjacent-months
            full-width
            :title-date-format="titleDateFormat"
          ></v-date-picker>
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center pa-2 mt-5">
        <v-btn
          rounded
          class="text-center"
          color="primary"
          @click="setExtraordinaryClosure"
          :loading="loading"
        >Salva chiusure</v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import DatePickerCommon from "@/components/common/DatePickerCommon.vue";
import calendarService from "@/services/calendar/calendar.service.js";
import settingService from "@/services/settings/settings.service.js";

export default {
  name: "CompanyHoursData",
  components: {
    DatePickerCommon,
  },
  data() {
    return {
      morningHours: [
        "00:00 pm",
        "00:15 am",
        "00:30 am",
        "00:45 am",
        "01:00 am",
        "01:15 am",
        "01:30 am",
        "01:45 am",
        "02:00 am",
        "02:15 am",
        "02:30 am",
        "02:45 am",
        "03:00 am",
        "03:15 am",
        "03:30 am",
        "03:45 am",
        "04:00 am",
        "04:15 am",
        "04:30 am",
        "04:45 am",
        "05:00 am",
        "05:15 am",
        "05:30 am",
        "05:45 am",
        "06:00 am",
        "06:15 am",
        "06:30 am",
        "06:45 am",
        "07:00 am",
        "07:15 am",
        "07:30 am",
        "07:45 am",
        "08:00 am",
        "08:15 am",
        "08:30 am",
        "08:45 am",
        "09:00 am",
        "09:15 am",
        "09:30 am",
        "09:45 am",
        "10:00 am",
        "10:15 am",
        "10:30 am",
        "10:45 am",
        "11:00 am",
        "11:15 am",
        "11:30 am",
        "11:45 am",
        "12:00 am",
        "12:15 am",
        "12:30 am",
        "12:45 am",
        "01:00 pm",
      ],
      afternoonHours: [
        "01:00 pm",
        "01:15 pm",
        "01:30 pm",
        "01:45 pm",
        "02:00 pm",
        "02:15 pm",
        "02:30 pm",
        "02:45 pm",
        "03:00 pm",
        "03:15 pm",
        "03:30 pm",
        "03:45 pm",
        "04:00 pm",
        "04:15 pm",
        "04:30 pm",
        "04:45 pm",
        "05:00 pm",
        "05:15 pm",
        "05:30 pm",
        "05:45 pm",
        "06:00 pm",
        "06:15 pm",
        "06:30 pm",
        "06:45 pm",
        "07:00 pm",
        "07:15 pm",
        "07:30 pm",
        "07:45 pm",
        "08:00 pm",
        "08:15 pm",
        "08:30 pm",
        "08:45 pm",
        "09:00 pm",
        "09:15 pm",
        "09:30 pm",
        "09:45 pm",
        "10:00 pm",
        "10:15 pm",
        "10:30 pm",
        "10:45 pm",
        "11:00 pm",
        "11:15 pm",
        "11:30 pm",
        "11:45 pm",
      ],
      weekDefault: {
        0: {
          name: "Dom",
          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
        1: {
          name: "Lun",
          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
        2: {
          name: "Mar",
          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
        3: {
          name: "Mer",

          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
        4: {
          name: "Gio",
          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
        5: {
          name: "Ven",
          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
        6: {
          name: "Sab",
          hours: {
            morning: { open: "08:30 am", close: "12:30 am" },
            afternoon: { open: "02:30 pm", close: "11:30 pm" },
          },
          closed: false,
        },
      },
      companyTurns: {},
      extraordinaryClosure: [],
      min: '',
      loading: false,
    };
  },
  mounted() {
    if (!this.companyTurns) {
      this.companyTurns = this.weekDefault;
      this.setSetting("companyTurns", this.weekDefault);
    }

    this.getExtraordinaryClosure();

    let today = new Date();
    this.min = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
  },
  methods: {
    allowedDates(){
      return true
    },
    titleDateFormat(dates) {
      return dates.length + " giorni selezionati";
    },
    clearSelect(key) {
      for (const [index, el] of Object.entries(this.companyTurns)) {
        if (Number(index) === Number(key)) {
          el.hours.morning.open = "";
          el.hours.morning.close = "";
          el.hours.afternoon.open = "";
          el.hours.afternoon.close = "";
        }
      }
    },
    translator(settingKey) {
      return this.$translations.t("settings." + settingKey + ".title");
    },
    loadSettings: async function () {
      this.loading = false;
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    setSetting: function (key, value, message) {
      this.$settings.set(key, value).then(() => {
        if(!!message) this.$delegates.snackbar(message)
        // this.loadSettings();
      });
    },
    async getExtraordinaryClosure(){
      let closures = await calendarService.getExtraordinaryClosure({ fromToday: true })
      this.extraordinaryClosure = closures.rows.map(closure => {
        let date = new Date(closure.date)
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate()
      })
    },
    setExtraordinaryClosure(){
      this.loading = true;
      calendarService.setExtraordinaryClosure(this.extraordinaryClosure).then(() => {
        this.loading = false;
        this.$delegates.snackbar("Orari aggiornati")
      })
      .catch(() => {
        this.loading = false;
        this.$delegates.snackbar("Errore durante l'aggiornamento")
      })
    }
  },
  computed: {
    settingLoaded() {
      return this.settings.length > 0;
    },
  },
  settings: {
    companyTurns: {
      bind: "companyTurns",
    },
  },
};
</script>

<style>
</style>